import type {
  SideBarItem,
  PrimaryNavigation,
  MatchItem,
  MatchedMenuItem,
  ExtraItem,
} from './types';

export function getSecondaryNavigation(
  navigation: PrimaryNavigation,
  path: string,
): {
  navItems: SideBarItem[];
  currentItem?: MatchedMenuItem;
  extraItems?: ExtraItem[];
} {
  //match path
  const currentNav = navigation.filter((item) => path.startsWith(item.href));
  if (currentNav.length > 0 && currentNav[0]?.singleFile) {
    return { navItems: [] };
  }
  if (currentNav.length > 0 && currentNav[0]?.posts) {
    const secondaryNavItem = filterNavData(path as string, currentNav[0].posts);
    const extraItems = findIslandItems(currentNav[0], [0]);
    return {
      navItems: currentNav[0].posts,
      currentItem: secondaryNavItem,
      extraItems: extraItems.length > 0 ? extraItems : [],
    };
  }
  return { navItems: [] };
}

export function flattenNavData(
  key: string,
  menuData: SideBarItem[],
): SideBarItem[] {
  const currentSection = menuData[0]?.posts?.filter(
    (item) => item.key === key,
  )[0];
  const flattenedItems: SideBarItem[] = [];
  if (
    currentSection?.posts &&
    trimPath(currentSection.href) !== trimPath(currentSection.posts[0]?.href)
  ) {
    flattenedItems.push({
      name: currentSection.name,
      href: currentSection.href,
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  addChildrenItems(0, currentSection!.posts!, flattenedItems);
  return flattenedItems;
}

function addChildrenItems(
  index: number,
  inputMenu: SideBarItem[],
  outputMenu: SideBarItem[],
): void {
  if (index >= inputMenu.length) return;
  if (Array.isArray(inputMenu[index]?.posts)) {
    outputMenu.push({
      name: inputMenu[index].name,
      href: inputMenu[index].href,
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    addChildrenItems(0, inputMenu[index].posts!, outputMenu);
  } else if (inputMenu[index]) {
    outputMenu.push({
      name: inputMenu[index].name,
      href: inputMenu[index].href,
    });
  }
  addChildrenItems(index + 1, inputMenu, outputMenu);
}

export function getNavParents(
  target: string,
  menuData: SideBarItem[],
  ancestors: { name: string; href: string }[] = [],
): { name: string; href: string }[] | undefined {
  for (const item of menuData) {
    if (trimPath(item.href) === trimPath(target)) {
      return ancestors.concat({ name: item.name, href: '' });
    }
    if (item.posts !== undefined) {
      const found = getNavParents(
        target,
        item.posts,
        ancestors.concat({ name: item.name, href: item.href }),
      );
      if (found) {
        return found;
      }
    }
  }
  return undefined;
}

export function findMatchPath(
  href: string | null,
  menuData: SideBarItem[],
): MatchItem {
  let returnMatch: MatchItem = { match: false };
  menuData.forEach((item, index) => {
    const matchPath = trimPath(item.href);
    if (item.isDynamic) {
      if (matchPath && href?.includes(matchPath)) {
        returnMatch = { match: true, index, items: menuData };
      }
    } else if (href === matchPath) {
      returnMatch = { match: true, index, items: menuData };
    }

    if (item.posts) {
      const foundMatch = findMatchPath(href, item.posts);
      if (foundMatch.match) {
        returnMatch = foundMatch;
      }
    }
  });
  return returnMatch;
}

export function NavigationArrayToObject(
  navArray: PrimaryNavigation,
  key: string,
): Record<string, SideBarItem> {
  const initialValue = {};
  return navArray.reduce((result, item) => {
    return {
      ...result,
      [item[key as keyof typeof item] as string]: item,
    };
  }, initialValue);
}

function filterNavData(
  path: string,
  navigation: PrimaryNavigation,
): MatchedMenuItem {
  let matchedItem: MatchedMenuItem = { href: '', isRoot: true, index: -1 };
  navigation.forEach((navItem) => {
    if (path === trimPath(navItem.href)) {
      matchedItem = {
        key: navItem.key,
        href: navItem.href,
        name: navItem.name,
        index: 0,
        items: navItem.posts ? navItem.posts : [],
        isRoot: true,
      };
    } else if (navItem.useInPath && path.includes(navItem.useInPath)) {
      matchedItem = {
        key: navItem.key,
        href: navItem.href,
        name: navItem.name,
        index: 0,
        items: navItem.posts ? navItem.posts : [],
        isRoot: true,
      };
    } else if (navItem.isDynamic) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (path.includes(trimPath(navItem.href)!)) {
        matchedItem = {
          key: navItem.key,
          href: navItem.href,
          name: navItem.name,
          index: 0,
          items: navItem.posts ? navItem.posts : [],
          isRoot: true,
        };
      }
    } else if (navItem.posts !== undefined) {
      const foundMatch = findMatchPath(path, navItem.posts);
      if (foundMatch.match) {
        matchedItem = {
          key: navItem.key,
          href: navItem.href,
          name: navItem.name,
          index: foundMatch.index ? foundMatch.index : -1,
          items: foundMatch.items,
          isRoot: false,
        };
      }
    }
  });
  return matchedItem;
}

function findIslandItems(
  navigation: SideBarItem,
  depths: number[],
): ExtraItem[] {
  let extraItems: ExtraItem[] = [];
  navigation.posts?.forEach((navItem, i) => {
    if (navItem.includeInNav) {
      extraItems.push({
        href: navItem.href,
        name: navItem.name,
        description: navItem.description,
        icon: navItem.icon,
        depth: depths,
      });
    }
    if (navItem.posts !== undefined) {
      const moreItems = findIslandItems(navItem, [...depths, i]);
      extraItems = [...moreItems, ...extraItems];
    }
  });
  return extraItems;
}

export function trimPath(pathToTrim: string | undefined): string | undefined {
  if (Boolean(pathToTrim) && pathToTrim?.at(-1) === '/') {
    return pathToTrim.slice(0, -1);
  }
  return pathToTrim;
}

export function isItemSelected(item: SideBarItem, path: string): boolean {
  let isSelected = false;
  if (item.hideChildrenSide) {
    if (
      trimPath(item.href) === path ||
      trimPath(item.href) === path.substring(0, path.lastIndexOf('/'))
    ) {
      isSelected = true;
    }
  } else if (item.isDynamic) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (path.includes(trimPath(item.href)!)) {
      isSelected = true;
    }
  } else if (trimPath(item.href) === path) {
    isSelected = true;
  }
  return isSelected;
}

export function flattenItems(navigation: SideBarItem[]): SideBarItem[] {
  return navigation.flatMap((item) => {
    if (item.posts && item.posts.length > 0) {
      return [...flattenItems(item.posts), item];
    }
    return [item];
  });
}
