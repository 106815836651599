export const Storage = {
  name: 'Storage',
  href: '/docs/storage',
  key: 'storage',
  isProduct: true,
  posts: [
    {
      name: 'Storage on Vercel',
      href: '/docs/storage',
      description: 'Learn about Vercel’s storage solutions',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'Vercel KV',
      href: '/docs/storage/vercel-kv',
      description: 'Durable Redis database to store and retrieve JSON data',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/storage/vercel-kv/quickstart',
          singleFile: true,
        },
        {
          name: 'SDK Reference',
          href: '/docs/storage/vercel-kv/kv-reference',
          singleFile: true,
        },
        {
          name: 'REST API Reference',
          href: '/docs/storage/vercel-kv/rest-api',
          singleFile: true,
        },
        {
          name: 'Redis Compatibility',
          href: '/docs/storage/vercel-kv/redis-compatibility',
          singleFile: true,
        },
        {
          name: 'Limits',
          href: '/docs/storage/vercel-kv/limits',
          singleFile: true,
        },
        {
          name: 'Pricing',
          href: '/docs/storage/vercel-kv/usage-and-pricing',
          singleFile: true,
        },
        {
          name: 'Error Codes',
          href: '/docs/storage/vercel-kv/vercel-kv-error-codes',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Vercel Postgres',
      href: '/docs/storage/vercel-postgres',
      description: 'Serverless SQL database integrated with Vercel Functions',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/storage/vercel-postgres/quickstart',
          singleFile: true,
        },
        {
          name: 'SDK Reference',
          href: '/docs/storage/vercel-postgres/sdk',
          singleFile: true,
        },
        {
          name: 'Using an ORM',
          href: '/docs/storage/vercel-postgres/using-an-orm',
          singleFile: true,
        },
        {
          name: 'Local Development',
          href: '/docs/storage/vercel-postgres/local-development',
          singleFile: true,
        },
        {
          name: 'Postgres Compatibility',
          href: '/docs/storage/vercel-postgres/supported-postgresql-extensions',
          singleFile: true,
        },
        {
          name: 'Limits',
          href: '/docs/storage/vercel-postgres/limits',
          singleFile: true,
        },
        {
          name: 'Pricing',
          href: '/docs/storage/vercel-postgres/usage-and-pricing',
          singleFile: true,
        },
        {
          name: 'FAQ',
          href: '/docs/storage/vercel-postgres/faq',
          singleFile: true,
        },
        {
          name: 'Error Codes',
          href: '/docs/storage/vercel-postgres/vercel-postgres-error-codes',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Vercel Blob',
      href: '/docs/storage/vercel-blob',
      description:
        'File serving and uploading via a global network with unique URLs',
      isProduct: true,
      posts: [
        {
          name: 'Server Upload Quickstart',
          href: '/docs/storage/vercel-blob/server-upload',
          singleFile: true,
        },
        {
          name: 'Client Upload Quickstart',
          href: '/docs/storage/vercel-blob/client-upload',
          singleFile: true,
        },
        {
          name: 'SDK Reference',
          href: '/docs/storage/vercel-blob/using-blob-sdk',
          singleFile: true,
        },
        {
          name: 'Usage & Pricing',
          href: '/docs/storage/vercel-blob/usage-and-pricing',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Edge Config',
      href: '/docs/storage/edge-config',
      description: 'Global data store designed for experimentation',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/storage/edge-config/get-started',
          singleFile: true,
        },
        {
          name: 'Using Edge Config',
          href: '/docs/storage/edge-config/using-edge-config',
          singleFile: true,
        },
        {
          name: 'Manage with the Vercel API',
          href: '/docs/storage/edge-config/vercel-api',
          singleFile: true,
        },
        {
          name: 'Manage with the Dashboard',
          href: '/docs/storage/edge-config/edge-config-dashboard',
          singleFile: true,
        },
        {
          name: 'SDK Reference',
          href: '/docs/storage/edge-config/edge-config-sdk',
          singleFile: true,
        },
        {
          name: 'Edge Config Integrations',
          href: '/docs/storage/edge-config/integrations',
          posts: [
            {
              name: 'LaunchDarkly',
              href: '/docs/storage/edge-config/integrations/launchdarkly-edge-config',
              singleFile: true,
            },
            {
              name: 'Statsig',
              href: '/docs/storage/edge-config/integrations/statsig-edge-config',
              singleFile: true,
            },
            {
              name: 'Hypertune',
              href: '/docs/storage/edge-config/integrations/hypertune-edge-config',
              singleFile: true,
            },
            {
              name: 'Split',
              href: '/docs/storage/edge-config/integrations/split-edge-config',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Limits & Pricing',
          href: '/docs/storage/edge-config/edge-config-limits',
          singleFile: true,
        },
      ],
    },
  ],
};
