export const Infrastructure = {
  name: 'Infrastructure',
  href: '/docs/infrastructure/',
  key: 'infrastructure',
  isProduct: true,
  posts: [
    {
      name: 'Edge Network',
      href: '/docs/edge-network/overview',
      description: 'Configurable CDN with caching, compute, and routing rules',
      isProduct: true,
      posts: [
        {
          name: 'Regions',
          href: '/docs/edge-network/regions',
          singleFile: true,
        },
        {
          name: 'Edge Cache',
          href: '/docs/edge-network/caching',
          description: 'Content caching at the edge for fast responses',
          isProduct: true,
          singleFile: true,
        },
        {
          name: 'Redirects',
          href: '/docs/edge-network/redirects',
          singleFile: true,
        },
        {
          name: 'Rewrites',
          href: '/docs/edge-network/rewrites',
          singleFile: true,
        },
        {
          name: 'Headers',
          href: '/docs/edge-network/headers',
          posts: [
            {
              name: 'Content Security Policy',
              href: '/docs/edge-network/security-headers',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Compression',
          href: '/docs/edge-network/compression',
          singleFile: true,
        },
        {
          name: 'Directory Listing',
          href: '/docs/edge-network/directory-listing',
          singleFile: true,
        },
        {
          name: 'FAQ',
          href: '/docs/edge-network/frequently-asked-questions',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Vercel Functions',
      href: '/docs/functions',
      description: 'Code on-demand without managing your own infrastructure',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/functions/quickstart',
          singleFile: true,
        },
        {
          name: 'Concepts',
          href: '/docs/functions/concepts',
          singleFile: true,
        },
        {
          name: 'Choosing a Runtime',
          href: '/docs/functions/runtimes',
          posts: [
            {
              name: 'Node.js',
              href: '/docs/functions/runtimes/node-js',
              posts: [
                {
                  name: 'Node.js Versions',
                  href: '/docs/functions/runtimes/node-js/node-js-versions',
                  singleFile: true,
                },
                {
                  name: 'Advanced Node.js Configuration',
                  href: '/docs/functions/runtimes/node-js/advanced-node-configuration',
                  singleFile: true,
                },
              ],
            },
            {
              name: 'Go',
              href: '/docs/functions/runtimes/go',
              singleFile: true,
            },
            {
              name: 'Python',
              href: '/docs/functions/runtimes/python',
              singleFile: true,
            },
            {
              name: 'Ruby',
              href: '/docs/functions/runtimes/ruby',
              singleFile: true,
            },
            {
              name: 'Edge Runtime',
              href: '/docs/functions/runtimes/edge-runtime',
            },
          ],
        },
        {
          name: 'Functions API Reference',
          href: '/docs/functions/functions-api-reference',
          posts: [
            {
              name: '@vercel/functions',
              href: '/docs/functions/vercel-functions-package',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Configuring Functions',
          href: '/docs/functions/configuring-functions',
          posts: [
            {
              name: 'Runtime',
              href: '/docs/functions/configuring-functions/runtime',
              singleFile: true,
            },
            {
              name: 'Region',
              href: '/docs/functions/configuring-functions/region',
              singleFile: true,
            },
            {
              name: 'Duration',
              href: '/docs/functions/configuring-functions/duration',
              singleFile: true,
            },
            {
              name: 'Memory / CPU',
              href: '/docs/functions/configuring-functions/memory',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Streaming Functions',
          href: '/docs/functions/streaming-functions',
          singleFile: false,
          posts: [
            {
              name: 'Streaming Concepts',
              href: '/docs/functions/streaming',
              singleFile: true,
            },
            {
              name: 'Quickstart',
              href: '/docs/functions/streaming/quickstart',
              singleFile: true,
            },
            {
              name: 'Streaming Examples',
              href: '/docs/functions/streaming/streaming-examples',
              singleFile: true,
            },
          ],
        },
        {
          name: 'OG Image Generation',
          href: '/docs/functions/og-image-generation',
          posts: [
            {
              name: '@vercel/og',
              href: '/docs/functions/og-image-generation/og-image-api',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Using WebAssembly',
          href: '/docs/functions/wasm',
          singleFile: true,
        },
        {
          name: 'Logs',
          href: '/docs/functions/logs',
          singleFile: true,
        },
        {
          name: 'Limitations',
          href: '/docs/functions/limitations',
          singleFile: true,
        },
        {
          name: 'Usage & Pricing',
          href: '/docs/functions/usage-and-pricing',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Edge Middleware',
      href: '/docs/functions/edge-middleware',
      description: 'Code that executes before a request is processed on a site',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/functions/edge-middleware/quickstart',
          singleFile: true,
        },
        {
          name: 'Middleware API',
          href: '/docs/functions/edge-middleware/middleware-api',
          singleFile: true,
        },
        {
          name: 'Edge Runtime',
          href: '/docs/functions/edge-middleware/edge-runtime',
          singleFile: true,
          isDuplicate: true,
        },
        {
          name: 'Limitations',
          href: '/docs/functions/edge-middleware/limitations',
          singleFile: true,
        },
        {
          name: 'Usage & Pricing',
          href: '/docs/functions/edge-middleware/usage-and-pricing',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Image Optimization',
      href: '/docs/image-optimization',
      description:
        'Serve high-quality images with minimal impact on page load times',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/image-optimization/quickstart',
          singleFile: true,
        },
        {
          name: 'Limits & Pricing',
          href: '/docs/image-optimization/limits-and-pricing',
          singleFile: true,
        },
        {
          name: 'Managing Costs',
          href: '/docs/image-optimization/managing-image-optimization-costs',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Incremental Static Regeneration',
      href: '/docs/incremental-static-regeneration',
      description: 'Create or update content without redeploying your site',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/incremental-static-regeneration/quickstart',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Data Cache',
      href: '/docs/infrastructure/data-cache',
      description: 'Specialized cache for storing responses from fetches',
      isProduct: true,
      posts: [
        {
          name: 'Managing Data Cache',
          href: '/docs/infrastructure/data-cache/manage-data-cache',
        },
        {
          name: 'Usage & Pricing',
          href: '/docs/infrastructure/data-cache/limits-and-pricing',
        },
      ],
    },
    {
      name: 'Cron Jobs',
      description: 'Time-based scheduling to automate repetitive tasks',
      isProduct: true,
      href: '/docs/cron-jobs',
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/cron-jobs/quickstart',
        },
        {
          name: 'Manage Cron Jobs',
          href: '/docs/cron-jobs/manage-cron-jobs',
        },
        {
          name: 'Usage & Pricing',
          href: '/docs/cron-jobs/usage-and-pricing',
        },
      ],
    },
  ],
};
