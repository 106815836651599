export const Marketplace = {
  name: 'Integrations',
  href: '/docs/integrations',
  key: 'integrations',
  isProduct: true,
  posts: [
    {
      name: 'Integration Overview',
      href: '/docs/integrations',
      isProduct: true,
      description:
        "Learn how to extend Vercel's capabilities by integrating with your preferred providers",
    },
    {
      name: 'Extend Vercel',
      href: '/docs/integrations/install-an-integration',
      description: 'Install an integration to extend Vercel',
      isProduct: true,
      posts: [
        {
          name: 'Add a Native Integration',
          href: '/docs/integrations/install-an-integration/product-integration',
          singleFile: true,
        },
        {
          name: 'Add a Connectable Account',
          href: '/docs/integrations/install-an-integration/add-a-connectable-account',
          singleFile: true,
        },
        {
          name: 'Permissions and Access',
          href: '/docs/integrations/install-an-integration/manage-integrations-reference',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Integrate with Vercel',
      href: '/docs/integrations/create-integration',
      description: 'Create an integration to integrate with Vercel',
      isProduct: true,
      posts: [
        {
          name: 'Create a Native Integration',
          href: '/docs/integrations/marketplace-product',
          singleFile: true,
        },
        {
          name: 'Submission Requirements',
          href: '/docs/integrations/create-integration/submit-integration',
          singleFile: true,
        },
        {
          name: 'Approval Checklist',
          href: '/docs/integrations/create-integration/approval-checklist',
          singleFile: true,
        },
        {
          name: 'Native Integrations Rest API',
          href: '/docs/integrations/marketplace-api',
          singleFile: true,
        },
        // {
        //   name: 'Marketplace Integration Flows',
        //   href: '/docs/integrations/marketplace-flows',
        //   singleFile: true,
        // },
      ],
    },
    {
      name: 'AI',
      description: 'Extend your projects with AI services and models',
      href: '/docs/integrations/ai',
      isProduct: true,
      posts: [
        {
          name: 'Adding a Provider',
          href: '/docs/integrations/ai/adding-a-provider',
          singleFile: true,
        },
        {
          name: 'Adding a Model',
          href: '/docs/integrations/ai/adding-a-model',
          singleFile: true,
        },
        {
          name: 'OpenAI',
          href: '/docs/integrations/ai/openai',
          singleFile: true,
        },
        {
          name: 'Anyscale',
          href: '/docs/integrations/ai/anyscale',
          singleFile: true,
        },
        {
          name: 'Pinecone',
          href: '/docs/integrations/ai/pinecone',
          singleFile: true,
        },
        {
          name: 'Replicate',
          href: '/docs/integrations/ai/replicate',
          singleFile: true,
        },
        {
          name: 'LMNT',
          href: '/docs/integrations/ai/lmnt',
          singleFile: true,
        },
        {
          name: 'Modal',
          href: '/docs/integrations/ai/modal',
          singleFile: true,
        },
        {
          name: 'Elevenlabs',
          href: '/docs/integrations/ai/elevenlabs',
          singleFile: true,
        },
        {
          name: 'Fal',
          href: '/docs/integrations/ai/fal',
          singleFile: true,
        },
        {
          name: 'Perplexity',
          href: '/docs/integrations/ai/perplexity',
          singleFile: true,
        },
        {
          name: 'Together AI',
          href: '/docs/integrations/ai/togetherai',
          singleFile: true,
        },
      ],
    },
    {
      name: 'CMS',
      description: 'Integrate with Content Management Systems',
      href: '/docs/integrations/cms',
      isProduct: true,
      posts: [
        {
          name: 'Agility CMS',
          href: '/docs/integrations/cms/agility-cms',
          singleFile: true,
        },
        {
          name: 'ButterCMS',
          href: '/docs/integrations/cms/butter-cms',
          singleFile: true,
        },
        {
          name: 'Contentful',
          href: '/docs/integrations/cms/contentful',
          singleFile: true,
        },
        {
          name: 'DatoCMS',
          href: '/docs/integrations/cms/dato-cms',
          singleFile: true,
        },
        {
          name: 'Formspree',
          href: '/docs/integrations/cms/formspree',
          singleFile: true,
        },
        {
          name: 'Makeswift',
          href: '/docs/integrations/cms/makeswift',
          singleFile: true,
        },
        {
          name: 'Sanity',
          href: '/docs/integrations/cms/sanity',
          singleFile: true,
        },
        {
          name: 'Sitecore',
          href: '/docs/integrations/cms/sitecore',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Ecommerce',
      description: 'Integrate with Ecommerce platforms',
      href: '/docs/integrations/ecommerce',
      posts: [
        {
          name: 'Shopify',
          href: '/docs/integrations/ecommerce/shopify',
          singleFile: true,
        },
      ],
    },
    {
      name: 'External Platforms',
      href: '',
      excludeFromNavHoverCard: true,
      posts: [
        {
          name: 'Cloudflare',
          href: '/docs/integrations/external-platforms/cloudflare',
          singleFile: true,
        },
        {
          name: 'Kubernetes',
          href: '/docs/integrations/external-platforms/kubernetes',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Sign in with Vercel',
      description: 'Integrate with Vercel for user authentication',
      href: '/docs/integrations/sign-in-with-vercel',
      isProduct: true,
    },
  ],
};
