import { documentationNavigation } from './documentation';

export const primaryNavigation = [
  {
    name: 'Documentation',
    href: '/docs',
    posts: documentationNavigation,
    home: true,
  },
  {
    name: 'Guides',
    href: '/guides',
    singleFile: true,
  },
  {
    name: 'Help',
    href: '/help',
    singleFile: true,
  },
];
