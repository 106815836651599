import { PopularFrameworkList } from './common';

export const Platform = {
  name: 'Platform',
  href: '/docs/platform',
  key: 'platform',
  hasHighlight: true,
  posts: [
    {
      name: 'Get Started',
      href: '/docs/getting-started-with-vercel',
      description: 'Build for the web and learn to use our platform',
      highlight: true,
      open: false,
      posts: [
        {
          name: 'Step 1 – Projects & Deployments',
          href: '/docs/getting-started-with-vercel/projects-deployments',
          posts: [
            {
              name: 'Option 1 - Use a Template',
              href: '/docs/getting-started-with-vercel/template',
              singleFile: true,
            },
            {
              name: 'Option 2 - Import an Existing Project',
              href: '/docs/getting-started-with-vercel/import',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Step 2 – Add a Domain',
          href: '/docs/getting-started-with-vercel/domains',
          posts: [
            {
              name: 'Option 1 - Buy a domain',
              href: '/docs/getting-started-with-vercel/buy-domain',
              singleFile: true,
            },
            {
              name: 'Option 2 - Transfer an existing domain',
              href: '/docs/getting-started-with-vercel/use-existing',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Step 3 – Collaborate',
          href: '/docs/getting-started-with-vercel/collaborate',
          singleFile: true,
        },
        {
          name: 'Next Steps',
          href: '/docs/getting-started-with-vercel/next-steps',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Incremental Migration',
      href: '/docs/incremental-migration',
      description: 'Migrate your site to Vercel with minimum risk',
      isProduct: false,
      posts: [
        {
          name: 'Migration Guide',
          href: '/docs/incremental-migration/migration-guide',
        },
        {
          name: 'Technical Guidelines',
          href: '/docs/incremental-migration/technical-guidelines',
        },
      ],
    },
    {
      name: 'Frameworks',
      href: '/docs/frameworks',
      description: 'Deploy with the framework of your choice on our platform',
      posts: [
        {
          name: 'Next.js',
          href: '/docs/frameworks/nextjs',
          singleFile: true,
        },
        {
          name: 'SvelteKit',
          href: '/docs/frameworks/sveltekit',
          singleFile: true,
        },
        //The framework list will be alphabetized
        ...PopularFrameworkList,
        {
          name: 'Supported Frameworks',
          href: '/docs/frameworks/more-frameworks',
        },
      ],
    },
    {
      name: 'Projects',
      href: '/docs/projects/overview',
      description: 'A Project groups deployments and custom domains',
      posts: [
        {
          name: 'Project Dashboard',
          href: '/docs/projects/project-dashboard',
        },
        {
          name: 'Monorepos',
          href: '/docs/monorepos',
          posts: [
            {
              name: `Turborepo`,
              href: '/docs/monorepos/turborepo',
            },
            {
              name: `Nx`,
              href: '/docs/monorepos/nx',
            },
            {
              name: `Remote Caching`,
              href: '/docs/monorepos/remote-caching',
            },
          ],
        },
        {
          name: 'Environment Variables',
          href: '/docs/projects/environment-variables',
          posts: [
            {
              name: 'Managing Environment Variables',
              href: '/docs/projects/environment-variables/managing-environment-variables',
              singleFile: true,
            },
            {
              name: 'Shared Environment Variables',
              href: '/docs/projects/environment-variables/shared-environment-variables',
              singleFile: true,
            },
            {
              name: 'System Environment Variables',
              href: '/docs/projects/environment-variables/system-environment-variables',
              singleFile: true,
            },
            {
              name: 'Framework Environment Variables',
              href: '/docs/projects/environment-variables/framework-environment-variables',
              singleFile: true,
            },
            {
              name: 'Reserved Environment Variables',
              href: '/docs/projects/environment-variables/reserved-environment-variables',
              singleFile: true,
            },
            {
              name: 'Sensitive Environment Variables',
              href: '/docs/projects/environment-variables/sensitive-environment-variables',
              singleFile: true,
            },
          ],
        },
        {
          name: 'vercel.json',
          href: '/docs/projects/project-configuration',
          description: 'Configure your project with vercel.json',
          posts: [
            {
              name: 'Git Configuration',
              href: '/docs/projects/project-configuration/git-configuration',
              singleFile: true,
            },
            {
              name: 'Global Configuration',
              href: '/docs/projects/project-configuration/global-configuration',
              singleFile: true,
            },
          ],
        },
      ],
    },
    {
      name: 'Builds',
      href: '/docs/deployments/builds',
      description: 'Learn how your projects are built and configured',
      posts: [
        {
          name: `Configure a Build`,
          href: '/docs/deployments/configure-a-build',
          singleFile: true,
        },
        {
          name: `Package Managers`,
          href: '/docs/deployments/builds/package-managers',
          singleFile: true,
        },
        {
          name: `Concurrent Builds`,
          href: '/docs/deployments/concurrent-builds',
          singleFile: true,
        },
        {
          name: `Build Features`,
          href: '/docs/deployments/build-features',
          singleFile: true,
        },
        {
          name: `Build Image`,
          href: '/docs/deployments/build-image',
          posts: [
            {
              name: 'Build Image',
              href: '/docs/deployments/build-image/build-image',
            },
            {
              name: 'Build Image (legacy)',
              href: '/docs/deployments/build-image/build-image-legacy',
            },
          ],
        },
        {
          name: `Troubleshoot a Build`,
          href: '/docs/deployments/troubleshoot-a-build',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Deployments',
      href: '/docs/deployments/overview',
      description: 'How your sites are generated and configured',
      posts: [
        {
          name: `Deploy to Vercel`,
          href: '/docs/deployments/deployment-methods',
          posts: [
            {
              name: 'Git Integrations',
              href: '/docs/deployments/git',
              posts: [
                {
                  name: `Vercel for GitHub`,
                  href: '/docs/deployments/git/vercel-for-github',
                },
                {
                  name: `Vercel for GitLab`,
                  href: '/docs/deployments/git/vercel-for-gitlab',
                },
                {
                  name: `Vercel for Bitbucket`,
                  href: '/docs/deployments/git/vercel-for-bitbucket',
                },
                {
                  name: `Vercel for Azure Pipelines`,
                  href: '/docs/deployments/git/vercel-for-azure-pipelines',
                },
              ],
            },
            {
              name: `Vercel CLI`,
              href: '/docs/deployments/deploy-with-vercel-cli',
              singleFile: true,
            },
            {
              name: `Deploy Hooks`,
              href: '/docs/deployments/deploy-hooks',
              singleFile: true,
            },
            {
              name: 'Deploy Button',
              href: '/docs/deployments/deploy-button',
              posts: [
                {
                  name: 'Demo',
                  href: '/docs/deployments/deploy-button/demo',
                },
                {
                  name: 'Build Settings',
                  href: '/docs/deployments/deploy-button/build-settings',
                },
                {
                  name: 'Source',
                  href: '/docs/deployments/deploy-button/source',
                },
                {
                  name: 'Callback',
                  href: '/docs/deployments/deploy-button/callback',
                },
                {
                  name: 'Environment Variables',
                  href: '/docs/deployments/deploy-button/environment-variables',
                },
                {
                  name: 'Integrations',
                  href: '/docs/deployments/deploy-button/integrations',
                },
              ],
            },
          ],
        },

        {
          name: 'Deployment Environments',
          href: '/docs/deployments/environments',
          posts: [
            {
              name: 'Local Development',
              href: '/docs/deployments/local-env',
              singleFile: true,
            },
            {
              name: 'Pre-production',
              href: '/docs/deployments/pre-production',
              posts: [
                {
                  name: 'Preview Deployments',
                  href: '/docs/deployments/preview-deployments',
                  posts: [
                    {
                      name: 'Share a Preview Deployment',
                      href: '/docs/deployments/sharing-deployments',
                      singleFile: true,
                    },
                    {
                      name: 'Preview Deployment Suffix',
                      href: '/docs/deployments/preview-deployment-suffix',
                      singleFile: true,
                    },
                  ],
                },
              ],
            },
            {
              name: 'Production',
              href: '/docs/deployments/production-env',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Accessing your Deployment',
          href: '/docs/deployments/generated-urls',
          singleFile: true,
        },
        {
          name: 'Manage Deployments',
          href: '/docs/deployments/managing-deployments',
          posts: [
            {
              name: 'Promoting deployments',
              href: '/docs/deployments/promoting-a-deployment',
            },
            {
              name: 'Instant Rollback',
              href: '/docs/deployments/instant-rollback',
            },
            {
              name: 'Exclude Files',
              href: '/docs/deployments/vercel-ignore',
            },
            {
              name: 'Skew Protection',
              href: '/docs/deployments/skew-protection',
            },
          ],
        },

        {
          name: `Webhooks`,
          href: '/docs/observability/webhooks-overview',
          posts: [
            {
              name: 'Webhooks API',
              href: '/docs/observability/webhooks-overview/webhooks-api',
            },
          ],
        },
        {
          name: `Inspecting Open Graph Metadata`,
          href: '/docs/deployments/og-preview',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Domains',
      href: '/docs/projects/domains',
      description: 'Adding, deploying, redirecting and transferring domains',
      isProduct: true,
      posts: [
        {
          name: 'Working with Domains',
          href: '/docs/projects/domains/working-with-domains',
          posts: [
            {
              name: 'View & Search Domains',
              href: '/docs/projects/domains/view-and-search-domains',
              singleFile: true,
            },
            {
              name: 'Add a Custom Domain',
              href: '/docs/projects/domains/add-a-domain',
              singleFile: true,
            },
            {
              name: 'Deploy or Redirect a Domain',
              href: '/docs/projects/domains/deploying-and-redirecting',
              singleFile: true,
            },
            {
              name: 'Remove a Domain',
              href: '/docs/projects/domains/remove-a-domain',
              singleFile: true,
            },
            {
              name: 'Renew a Domain',
              href: '/docs/projects/domains/renew-a-domain',
              singleFile: true,
            },
            {
              name: 'Transfer your Domains',
              href: '/docs/projects/domains/transfer-your-domain',
              singleFile: true,
            },
            {
              name: 'Assign a Domain to a Branch',
              href: '/docs/projects/domains/assign-domain-to-a-git-branch',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Working with DNS',
          href: '/docs/projects/domains/working-with-dns',
          posts: [
            {
              name: 'Manage DNS records',
              href: '/docs/projects/domains/managing-dns-records',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Working with Nameservers',
          href: '/docs/projects/domains/working-with-nameservers',
          posts: [
            {
              name: 'Manage Nameservers',
              href: '/docs/projects/domains/managing-nameservers',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Working with SSL',
          href: '/docs/projects/domains/working-with-ssl',
          posts: [
            {
              name: 'Custom SSL Certificate',
              href: '/docs/projects/domains/custom-SSL-certificate',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Supported Domains for Purchase',
          href: '/docs/projects/domains/supported-domains',
        },
        {
          name: 'Troubleshooting Domains',
          href: '/docs/projects/domains/troubleshooting',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Going Live Checklist',
      href: '/docs/production-checklist',
      description: 'Pre-launch checklist for your project',
      isProduct: false,
      singleFile: true,
      posts: [],
    },
    {
      name: 'Pricing',
      href: '/docs/pricing',
      description: 'Pricing, plans, and spend management',
      posts: [
        {
          name: 'Spend Management',
          href: '/docs/pricing/spend-management',
          singleFile: true,
          description:
            'Get notified about or trigger actions based on your account spend',
        },
        {
          name: 'Calculating Resource Usage',
          href: '/docs/pricing/how-does-vercel-calculate-usage-of-resources',
          singleFile: true,
        },
        {
          name: 'Billing & Invoices',
          href: '/docs/pricing/understanding-my-invoice',
          singleFile: true,
          posts: [
            {
              name: 'Pro Billing FAQ',
              href: '/docs/accounts/plans/pro/billing',
              singleFile: true,
            },
            {
              name: 'Enterprise Billing FAQ',
              href: '/docs/accounts/plans/enterprise/billing',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Manage & Optimize Usage',
          href: '/docs/pricing/manage-and-optimize-usage',
          posts: [
            {
              name: 'Networking',
              href: '/docs/pricing/networking',
              singleFile: true,
            },
            {
              name: 'Serverless Functions',
              href: '/docs/pricing/serverless-functions',
              singleFile: true,
            },
            {
              name: 'Edge Functions',
              href: '/docs/pricing/edge-functions',
              singleFile: true,
            },
            {
              name: 'Edge Middleware',
              href: '/docs/pricing/edge-middleware',
              singleFile: true,
            },
            {
              name: 'Builds',
              href: '/docs/pricing/builds',
              singleFile: true,
            },
            {
              name: 'Remote Cache Artifacts',
              href: '/docs/pricing/artifacts',
              singleFile: true,
            },
            {
              name: 'Edge Config',
              href: '/docs/pricing/edge-config',
              singleFile: true,
            },
            {
              name: 'Data Cache',
              href: '/docs/pricing/data-cache',
              singleFile: true,
            },
            {
              name: 'Vercel Postgres',
              href: '/docs/pricing/postgres',
              singleFile: true,
            },
            {
              name: 'Vercel KV',
              href: '/docs/pricing/kv',
              singleFile: true,
            },
            {
              name: 'Observability',
              href: '/docs/pricing/observability',
              singleFile: true,
            },
            {
              name: 'Image Optimization',
              href: '/docs/pricing/image-optimization',
              singleFile: true,
            },
            {
              name: 'Legacy Metrics',
              href: '/docs/pricing/legacy',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Plans',
          href: '/docs/accounts/plans',
          posts: [
            {
              name: 'Hobby',
              href: '/docs/accounts/plans/hobby',
              singleFile: true,
            },
            {
              name: 'Pro',
              href: '/docs/accounts/plans/pro',
              posts: [
                {
                  name: 'Trials',
                  href: '/docs/accounts/plans/pro/trials',
                  singleFile: true,
                },
              ],
            },
            {
              name: 'Enterprise',
              href: '/docs/accounts/plans/enterprise',
            },
          ],
        },
      ],
    },
    {
      name: 'Resources',
      href: '/docs/resources',
      description: 'Learn about account management, error handling, and more',
      open: true,
      posts: [
        {
          name: 'Dashboard',
          href: '/docs/dashboard-features/',
          description: 'View and manage all aspects of the Vercel platform',
          isProduct: true,
          posts: [
            {
              name: 'Overview',
              href: '/docs/dashboard-features/overview',
            },
            {
              name: 'Notifications',
              href: '/docs/dashboard-features/notifications',
            },
            {
              name: 'Command Menu',
              href: '/docs/dashboard-features/command-menu',
            },
            {
              name: 'Support Center',
              href: '/docs/dashboard-features/support-center',
            },
          ],
        },
        {
          name: 'Account Management',
          href: '/docs/accounts',
          description: 'Create a Vercel identity and collaborate with teams',
          isProduct: true,
          posts: [
            {
              name: 'Create an Account',
              href: '/docs/accounts/create-an-account',
              singleFile: true,
            },
            {
              name: 'Manage Emails',
              href: '/docs/accounts/manage-emails',
              singleFile: true,
            },
            {
              name: 'Create a Team',
              href: '/docs/accounts/create-a-team',
              singleFile: true,
            },
            {
              name: 'Team Roles & Permissions',
              href: '/docs/accounts/team-members-and-roles',
              posts: [
                {
                  name: 'Access Roles',
                  href: '/docs/accounts/team-members-and-roles/access-roles',
                  posts: [
                    {
                      name: 'Team Roles Reference',
                      href: '/docs/accounts/team-members-and-roles/access-roles/team-level-roles',
                      singleFile: true,
                    },
                    {
                      name: 'Project Roles Reference',
                      href: '/docs/accounts/team-members-and-roles/access-roles/project-level-roles',
                      singleFile: true,
                    },
                  ],
                },
                {
                  name: 'Access Groups',
                  href: '/docs/accounts/team-members-and-roles/access-groups',
                  singleFile: true,
                },
                {
                  name: 'Managing Team Members',
                  href: '/docs/accounts/team-members-and-roles/managing-team-members',
                  singleFile: true,
                },
              ],
            },
          ],
        },
        {
          name: 'Limits',
          href: '/docs/limits/overview',
          description: 'List of limits and limitations applied on Vercel',
          isProduct: true,
          posts: [
            {
              name: 'Fair use Guidelines',
              href: '/docs/limits/fair-use-guidelines',
            },
          ],
        },
        {
          name: 'General Errors',
          href: '/docs/errors/error-list',
          description:
            'General error handling guidance, that covers dashboard related errors',
          singleFile: true,
        },
        {
          name: 'Error Codes',
          href: '/docs/errors',
          description: 'List of possible errors when interacting with Vercel',
          isProduct: false,
          hideChildrenSide: true,
          posts: [
            {
              name: 'BODY_NOT_A_STRING_FROM_FUNCTION',
              href: '/docs/errors/BODY_NOT_A_STRING_FROM_FUNCTION',
            },
            {
              name: 'DEPLOYMENT_BLOCKED',
              href: '/docs/errors/DEPLOYMENT_BLOCKED',
            },
            {
              name: 'DEPLOYMENT_DISABLED',
              href: '/docs/errors/DEPLOYMENT_DISABLED',
            },
            {
              name: 'DEPLOYMENT_DELETED',
              href: '/docs/errors/DEPLOYMENT_DELETED',
            },
            {
              name: 'DEPLOYMENT_NOT_FOUND',
              href: '/docs/errors/DEPLOYMENT_NOT_FOUND',
            },
            {
              name: 'DEPLOYMENT_NOT_READY_REDIRECTING',
              href: '/docs/errors/DEPLOYMENT_NOT_READY_REDIRECTING',
            },
            {
              name: 'DEPLOYMENT_PAUSED',
              href: '/docs/errors/DEPLOYMENT_PAUSED',
            },
            {
              name: 'DNS_HOSTNAME_EMPTY',
              href: '/docs/errors/DNS_HOSTNAME_EMPTY',
            },
            {
              name: 'DNS_HOSTNAME_NOT_FOUND',
              href: '/docs/errors/DNS_HOSTNAME_NOT_FOUND',
            },
            {
              name: 'DNS_HOSTNAME_RESOLVE_FAILED',
              href: '/docs/errors/DNS_HOSTNAME_RESOLVE_FAILED',
            },
            {
              name: 'DNS_HOSTNAME_RESOLVED_PRIVATE',
              href: '/docs/errors/DNS_HOSTNAME_RESOLVED_PRIVATE',
            },
            {
              name: 'DNS_HOSTNAME_SERVER_ERROR',
              href: '/docs/errors/DNS_HOSTNAME_SERVER_ERROR',
            },
            {
              name: 'EDGE_FUNCTION_INVOCATION_FAILED',
              href: '/docs/errors/EDGE_FUNCTION_INVOCATION_FAILED',
            },
            {
              name: 'EDGE_FUNCTION_INVOCATION_TIMEOUT',
              href: '/docs/errors/EDGE_FUNCTION_INVOCATION_TIMEOUT',
            },
            {
              name: 'FALLBACK_BODY_TOO_LARGE',
              href: '/docs/errors/FALLBACK_BODY_TOO_LARGE',
            },
            {
              name: 'FUNCTION_INVOCATION_FAILED',
              href: '/docs/errors/FUNCTION_INVOCATION_FAILED',
            },
            {
              name: 'FUNCTION_INVOCATION_TIMEOUT',
              href: '/docs/errors/FUNCTION_INVOCATION_TIMEOUT',
            },
            {
              name: 'FUNCTION_RATE_LIMIT',
              href: '/docs/errors/FUNCTION_RATE_LIMIT',
            },
            {
              name: 'FUNCTION_PAYLOAD_TOO_LARGE',
              href: '/docs/errors/FUNCTION_PAYLOAD_TOO_LARGE',
            },
            {
              name: 'FUNCTION_RESPONSE_PAYLOAD_TOO_LARGE',
              href: '/docs/errors/FUNCTION_RESPONSE_PAYLOAD_TOO_LARGE',
            },
            {
              name: 'INFINITE_LOOP_DETECTED',
              href: '/docs/errors/INFINITE_LOOP_DETECTED',
            },
            {
              name: 'INTERNAL_CACHE_ERROR',
              href: '/docs/errors/INTERNAL_CACHE_ERROR',
            },
            {
              name: 'INTERNAL_CACHE_KEY_TOO_LONG',
              href: '/docs/errors/INTERNAL_CACHE_KEY_TOO_LONG',
            },
            {
              name: 'INTERNAL_CACHE_LOCK_FULL',
              href: '/docs/errors/INTERNAL_CACHE_LOCK_FULL',
            },
            {
              name: 'INTERNAL_CACHE_LOCK_TIMEOUT',
              href: '/docs/errors/INTERNAL_CACHE_LOCK_TIMEOUT',
            },
            {
              name: 'INTERNAL_DEPLOYMENT_FETCH_FAILED',
              href: '/docs/errors/INTERNAL_DEPLOYMENT_FETCH_FAILED',
            },
            {
              name: 'INTERNAL_EDGE_FUNCTION_INVOCATION_FAILED',
              href: '/docs/errors/INTERNAL_EDGE_FUNCTION_INVOCATION_FAILED',
            },
            {
              name: 'INTERNAL_EDGE_FUNCTION_INVOCATION_TIMEOUT',
              href: '/docs/errors/INTERNAL_EDGE_FUNCTION_INVOCATION_TIMEOUT',
            },
            {
              name: 'INTERNAL_OPTIMIZED_IMAGE_REQUEST_FAILED',
              href: '/docs/errors/INTERNAL_OPTIMIZED_IMAGE_REQUEST_FAILED',
            },
            // {
            //   name: 'INTERNAL_PRERENDER_REVALIDATE_FAILED',
            //   href: '/docs/errors/INTERNAL_PRERENDER_REVALIDATE_FAILED',
            // },
            {
              name: 'INTERNAL_ROUTER_CANNOT_PARSE_PATH',
              href: '/docs/errors/INTERNAL_ROUTER_CANNOT_PARSE_PATH',
            },
            // {
            //   name: 'INTERNAL_TOO_MANY_CACHE_HEADERS',
            //   href: '/docs/errors/INTERNAL_TOO_MANY_CACHE_HEADERS',
            // },
            {
              name: 'INVALID_IMAGE_OPTIMIZE_REQUEST',
              href: '/docs/errors/INVALID_IMAGE_OPTIMIZE_REQUEST',
            },
            {
              name: 'OPTIMIZED_EXTERNAL_IMAGE_REQUEST_FAILED',
              href: '/docs/errors/OPTIMIZED_EXTERNAL_IMAGE_REQUEST_FAILED',
            },
            {
              name: 'OPTIMIZED_EXTERNAL_IMAGE_REQUEST_INVALID',
              href: '/docs/errors/OPTIMIZED_EXTERNAL_IMAGE_REQUEST_INVALID',
            },
            {
              name: 'OPTIMIZED_EXTERNAL_IMAGE_REQUEST_UNAUTHORIZED',
              href: '/docs/errors/OPTIMIZED_EXTERNAL_IMAGE_REQUEST_UNAUTHORIZED',
            },
            {
              name: 'REQUEST_HEADER_TOO_LARGE',
              href: '/docs/errors/REQUEST_HEADER_TOO_LARGE',
            },
            {
              name: 'ROUTER_EXTERNAL_TARGET_HANDSHAKE_ERROR',
              href: '/docs/errors/ROUTER_EXTERNAL_TARGET_HANDSHAKE_ERROR',
            },
            {
              name: 'INTERNAL_FUNCTION_INVOCATION_FAILED',
              href: '/docs/errors/INTERNAL_FUNCTION_INVOCATION_FAILED',
            },
            {
              name: 'INTERNAL_FUNCTION_INVOCATION_TIMEOUT',
              href: '/docs/errors/INTERNAL_FUNCTION_INVOCATION_TIMEOUT',
            },
            {
              name: 'INTERNAL_FUNCTION_NOT_FOUND',
              href: '/docs/errors/INTERNAL_FUNCTION_NOT_FOUND',
            },
            {
              name: 'INTERNAL_FUNCTION_NOT_READY',
              href: '/docs/errors/INTERNAL_FUNCTION_NOT_READY',
            },
            {
              name: 'INTERNAL_MISSING_RESPONSE_FROM_CACHE',
              href: '/docs/errors/INTERNAL_MISSING_RESPONSE_FROM_CACHE',
            },
            {
              name: 'INTERNAL_STATIC_REQUEST_FAILED',
              href: '/docs/errors/INTERNAL_STATIC_REQUEST_FAILED',
            },
            {
              name: 'INTERNAL_UNARCHIVE_FAILED',
              href: '/docs/errors/INTERNAL_UNARCHIVE_FAILED',
            },
            {
              name: 'INTERNAL_UNEXPECTED_ERROR',
              href: '/docs/errors/INTERNAL_UNEXPECTED_ERROR',
            },
            {
              name: 'INVALID_REQUEST_METHOD',
              href: '/docs/errors/INVALID_REQUEST_METHOD',
            },
            {
              name: 'MALFORMED_REQUEST_HEADER',
              href: '/docs/errors/MALFORMED_REQUEST_HEADER',
            },
            {
              name: 'NO_RESPONSE_FROM_FUNCTION',
              href: '/docs/errors/NO_RESPONSE_FROM_FUNCTION',
            },
            { name: 'NOT_FOUND', href: '/docs/errors/NOT_FOUND' },
            {
              name: 'RANGE_END_NOT_VALID',
              href: '/docs/errors/RANGE_END_NOT_VALID',
            },
            {
              name: 'RANGE_GROUP_NOT_VALID',
              href: '/docs/errors/RANGE_GROUP_NOT_VALID',
            },
            {
              name: 'RANGE_MISSING_UNIT',
              href: '/docs/errors/RANGE_MISSING_UNIT',
            },
            {
              name: 'RANGE_START_NOT_VALID',
              href: '/docs/errors/RANGE_START_NOT_VALID',
            },
            {
              name: 'RANGE_UNIT_NOT_SUPPORTED',
              href: '/docs/errors/RANGE_UNIT_NOT_SUPPORTED',
            },
            { name: 'TOO_MANY_RANGES', href: '/docs/errors/TOO_MANY_RANGES' },
            {
              name: 'RESOURCE_NOT_FOUND',
              href: '/docs/errors/RESOURCE_NOT_FOUND',
            },
            {
              name: 'ROUTER_CANNOT_MATCH',
              href: '/docs/errors/ROUTER_CANNOT_MATCH',
            },
            {
              name: 'ROUTER_EXTERNAL_TARGET_CONNECTION_ERROR',
              href: '/docs/errors/ROUTER_EXTERNAL_TARGET_CONNECTION_ERROR',
            },
            {
              name: 'ROUTER_EXTERNAL_TARGET_ERROR',
              href: '/docs/errors/ROUTER_EXTERNAL_TARGET_ERROR',
            },
            {
              name: 'ROUTER_TOO_MANY_HAS_SELECTIONS',
              href: '/docs/errors/ROUTER_TOO_MANY_HAS_SELECTIONS',
            },
            {
              name: 'TOO_MANY_FILESYSTEM_CHECKS',
              href: '/docs/errors/TOO_MANY_FILESYSTEM_CHECKS',
            },
            { name: 'TOO_MANY_FORKS', href: '/docs/errors/TOO_MANY_FORKS' },
            { name: 'URL_TOO_LONG', href: '/docs/errors/URL_TOO_LONG' },
          ],
        },
        {
          name: 'Release Phases',
          href: '/docs/release-phases',
          description: 'Phases of the Vercel Product release cycle',
          isProduct: true,
          singleFile: true,
          posts: [],
        },
        {
          name: 'Private Registry',
          href: '/docs/vercel-platform/private-registry',
          description: "Vercel's private registry",
          singleFile: true,
        },
        {
          name: 'Glossary',
          href: '/docs/vercel-platform/glossary',
          description: 'Common terms and concepts used by Vercel',
          singleFile: true,
        },
      ],
    },
  ],
};
