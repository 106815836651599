import lodash from 'lodash';

export const PopularFrameworkList = lodash.sortBy(
  [
    {
      name: 'Nuxt',
      href: '/docs/frameworks/nuxt',
      singleFile: true,
    },
    {
      name: 'Vite',
      href: '/docs/frameworks/vite',
      singleFile: true,
    },
    {
      name: 'Create React App',
      href: '/docs/frameworks/create-react-app',
      singleFile: true,
    },
    {
      name: 'Gatsby',
      href: '/docs/frameworks/gatsby',
      singleFile: true,
    },
    {
      name: 'Remix',
      href: '/docs/frameworks/remix',
      singleFile: true,
    },
    {
      name: 'Astro',
      href: '/docs/frameworks/astro',
      singleFile: true,
    },
  ],
  (framework) => framework.name,
);
