export const Observability = {
  name: 'Observability',
  href: '/docs/observability',
  key: 'observability',
  isProduct: true,
  posts: [
    {
      name: 'Frontend Observability',
      href: '/docs/observability',
      description: 'Monitor and analyze your frontend performance',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'Web Analytics',
      href: '/docs/analytics',
      description:
        'First-party, privacy-friendly analytics about website visitors',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/analytics/quickstart',
        },
        {
          name: 'Custom Events',
          href: '/docs/analytics/custom-events',
        },
        {
          name: 'Filtering',
          href: '/docs/analytics/filtering',
        },
        {
          name: 'Pricing',
          href: '/docs/analytics/limits-and-pricing',
        },
        {
          name: 'Privacy',
          href: '/docs/analytics/privacy-policy',
        },
        {
          name: 'Redacting Sensitive Data',
          href: '/docs/analytics/redacting-sensitive-data',
        },
        {
          name: '@vercel/analytics',
          href: '/docs/analytics/package',
        },
      ],
    },
    {
      name: 'Speed Insights',
      href: '/docs/speed-insights',
      description: 'Explore and improve your website performance',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/speed-insights/quickstart',
        },
        {
          name: 'Metrics',
          href: '/docs/speed-insights/metrics',
        },
        {
          name: '@vercel/speed-insights',
          href: '/docs/speed-insights/package',
        },
        {
          name: 'Limits & Pricing',
          href: '/docs/speed-insights/limits-and-pricing',
        },
        {
          name: 'Privacy',
          href: '/docs/speed-insights/privacy-policy',
        },
        {
          name: 'Disabling',
          href: '/docs/speed-insights/disable',
        },
        {
          name: 'Migrating to the Package',
          href: '/docs/speed-insights/migrating-from-legacy',
        },
      ],
    },
    {
      name: 'Monitoring',
      href: '/docs/observability/monitoring',
      description: 'Query and visualize your Vercel usage, traffic, and more',
      isProduct: true,
      posts: [
        {
          name: 'Quickstart',
          href: '/docs/observability/monitoring/quickstart',
          singleFile: true,
        },
        {
          name: 'Reference',
          href: '/docs/observability/monitoring/monitoring-reference',
          singleFile: true,
        },
        {
          name: 'Limits & Pricing',
          href: '/docs/observability/monitoring/limits-and-pricing',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Logs',
      href: '/docs/observability/logs',
      description: 'Search, inspect, and share your runtime logs',
      isProduct: true,
      posts: [
        {
          name: 'Build Logs',
          href: '/docs/deployments/logs',
          singleFile: true,
        },
        {
          name: 'Runtime Logs',
          href: '/docs/observability/runtime-logs',
          description: 'Search, inspect, and share your runtime logs',
          isProduct: true,
          singleFile: true,
        },
        {
          name: 'Activity Log',
          href: '/docs/observability/activity-log',
          description: 'List of events in your account in chronological order',
          isProduct: true,
          singleFile: true,
        },
        {
          name: 'Log Drains',
          href: '/docs/observability/log-drains',
          description:
            'Log collection integration with third-party log services',
          isProduct: true,
          posts: [
            {
              name: 'Configure Log Drains',
              href: '/docs/observability/log-drains/configure-log-drains',
            },
            {
              name: 'Log Drains Reference',
              href: '/docs/observability/log-drains/log-drains-reference',
            },
          ],
        },
      ],
    },

    {
      name: 'OpenTelemetry Collector',
      href: '/docs/observability/otel-overview',
      description: 'Send OTEL traces from Functions to APM vendors',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'Checks',
      href: '/docs/observability/checks-overview',
      description:
        'Checks API assesses your deployments quality and reliability',
      isProduct: true,
      posts: [
        {
          name: 'Creating Checks',
          href: '/docs/observability/checks-overview/creating-checks',
        },
        {
          name: 'Checks API',
          href: '/docs/observability/checks-overview/checks-api',
        },
      ],
    },
  ],
};
