export const Security = {
  name: 'Security',
  href: '/docs/security/overview',
  key: 'security',
  isProduct: true,
  posts: [
    {
      name: 'Compliance Measures',
      href: '/docs/security',
      isProduct: true,
      description: 'Compliance to standards such as SOC2, ISO 27001 & GDPR',
      posts: [
        {
          name: 'PCI DSS Integration',
          href: '/docs/security/pci-dss',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Shared Responsibility',
      href: '/docs/security/shared-responsibility',
      isProduct: true,
      description:
        'The shared responsibility model splits security tasks between Vercel and the user',
      singleFile: true,
    },
    {
      name: 'Firewall',
      href: '/docs/security/vercel-firewall',
      description: 'Protects websites from unauthorized access',
      isProduct: true,
      posts: [
        {
          name: 'DDoS Mitigation',
          href: '/docs/security/ddos-mitigation',
          singleFile: true,
          description: 'Protection against DDoS attacks',
          includeInNav: true,
        },
        {
          name: 'Attack Challenge Mode',
          href: '/docs/security/attack-challenge-mode',
          singleFile: true,
        },
        {
          name: 'Web Application Firewall',
          href: '/docs/security/vercel-waf',
          posts: [
            {
              name: 'IP Blocking',
              href: '/docs/security/vercel-waf/ip-blocking',
              singleFile: true,
            },
            {
              name: 'Custom Rules',
              href: '/docs/security/vercel-waf/custom-rules',
              posts: [
                {
                  name: 'Rule Configuration Reference',
                  href: '/docs/security/vercel-waf/rule-configuration',
                  singleFile: true,
                },
              ],
            },
            {
              name: 'Managed Rulesets',
              href: '/docs/security/vercel-waf/managed-rulesets',
              singleFile: true,
            },
            {
              name: 'Common Examples',
              href: '/docs/security/vercel-waf/examples',
              singleFile: true,
            },
          ],
        },
        {
          name: 'TLS Fingerprints',
          href: '/docs/security/tls-fingerprints',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Access Control',
      href: '/docs/security/access-control',
      description: 'Deployment protection with password and SSO',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'SAML SSO',
      href: '/docs/security/saml',
      description: 'Manage team members with third-party identity providers',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'HTTPS/SSL',
      href: '/docs/security/encryption',
      description: 'Default serving over HTTPS connections',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'Directory Sync',
      href: '/docs/security/directory-sync',
      description: 'Manage your teams with third-party identity providers',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'Secure Backend Access',
      href: '/docs/security/secure-backend-access',
      description:
        'Securely access your backend with private connections and OIDC federation',
      isProduct: true,
      posts: [
        {
          name: 'Secure Compute',
          href: '/docs/security/secure-compute',
          description: 'Create private connections with your backend cloud',
          isProduct: true,
          singleFile: true,
        },
        {
          name: 'OpenID Connect Federation',
          href: '/docs/security/secure-backend-access/oidc',
          description: 'Securely access your backend with OIDC federation',
          isProduct: true,
          badge: 'New',
          posts: [
            {
              name: 'Connect to Amazon Web Services (AWS)',
              href: '/docs/security/secure-backend-access/oidc/aws',
              singleFile: true,
            },
            {
              name: 'Connnect to Google Cloud Platform (GCP)',
              href: '/docs/security/secure-backend-access/oidc/gcp',
              singleFile: true,
            },
            {
              name: 'Connect to Microsoft Azure',
              href: '/docs/security/secure-backend-access/oidc/azure',
              singleFile: true,
            },
            {
              name: 'Connect to your own API',
              href: '/docs/security/secure-backend-access/oidc/api',
              singleFile: true,
            },
            {
              name: 'Reference',
              href: '/docs/security/secure-backend-access/oidc/reference',
              singleFile: true,
            },
          ],
        },
      ],
    },
    {
      name: 'Deployment Protection',
      href: '/docs/security/deployment-protection',
      description: 'Secure your deployments, and manage their access',
      isProduct: true,
      posts: [
        {
          name: 'Methods to Protect Deployments',
          href: '/docs/security/deployment-protection/methods-to-protect-deployments',
          posts: [
            {
              name: 'Vercel Authentication',
              href: '/docs/security/deployment-protection/methods-to-protect-deployments/vercel-authentication',
              singleFile: true,
            },
            {
              name: 'Password Protection',
              href: '/docs/security/deployment-protection/methods-to-protect-deployments/password-protection',
              singleFile: true,
            },
            {
              name: 'Trusted IPs',
              href: '/docs/security/deployment-protection/methods-to-protect-deployments/trusted-ips',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Methods to Bypass Deployment Protection',
          href: '/docs/security/deployment-protection/methods-to-bypass-deployment-protection',
          posts: [
            {
              name: 'Sharable Links',
              href: '/docs/security/deployment-protection/methods-to-bypass-deployment-protection/sharable-links',
              singleFile: true,
            },
            {
              name: 'Protection Bypass for Automation',
              href: '/docs/security/deployment-protection/methods-to-bypass-deployment-protection/protection-bypass-automation',
              singleFile: true,
            },
            {
              name: 'Deployment Protection Exceptions',
              href: '/docs/security/deployment-protection/methods-to-bypass-deployment-protection/deployment-protection-exceptions',
              singleFile: true,
            },
            {
              name: 'OPTIONS Allowlist',
              href: '/docs/security/deployment-protection/methods-to-bypass-deployment-protection/options-allowlist',
              singleFile: true,
            },
          ],
        },
      ],
    },
    {
      name: 'Deployment Retention',
      href: '/docs/security/deployment-retention',
      description: 'Manage your deployments and their lifecycle',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'Audit Logs',
      href: '/docs/observability/audit-log',
      description: 'Track and analyze your team members activities',
      isProduct: true,
      singleFile: true,
    },
    {
      name: 'Protected Git Scopes',
      href: '/docs/security/protected-git-scopes',
      description:
        'Limit other Vercel teams from deploying from your Git repositories',
      isProduct: true,
      singleFile: true,
    },
  ],
};
