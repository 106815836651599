import { NavigationArrayToObject } from '../utils';
import { Infrastructure } from './infrastructure';
import { Workflow } from './workflow';
import { Storage } from './storage';
import { Observability } from './observability';
import { Security } from './security';
import { CliApi } from './cli-api';
import { Marketplace } from './marketplace';
import { Platform } from './platform';

export interface ToC {
  name: string;
  href: string;
  level: number;
}

export type ToCData = ToC[];

export const documentationNavigation = [
  Platform,
  Infrastructure,
  Workflow,
  Storage,
  Observability,
  Marketplace,
  Security,
  CliApi,
  { name: 'All Products', href: '/docs/all-products', key: 'all-products' },
];

export const documentationObject = NavigationArrayToObject(
  documentationNavigation,
  'key',
);
