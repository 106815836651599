export const Workflow = {
  name: 'Workflow',
  href: '/docs/workflow-collaboration',
  key: 'workflow',
  isProduct: true,
  posts: [
    {
      name: 'Vercel Toolbar',
      href: '/docs/workflow-collaboration/vercel-toolbar',
      description: 'Manage your Vercel projects from your browser',
      isProduct: true,
      posts: [
        {
          name: 'Managing the Toolbar',
          href: '/docs/workflow-collaboration/vercel-toolbar/managing-toolbar',
          singleFile: true,
        },
        {
          name: 'Add to Your Environment',
          href: '/docs/workflow-collaboration/vercel-toolbar/in-production-and-localhost',
          singleFile: true,
          posts: [
            {
              name: 'Add to Localhost',
              href: '/docs/workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-localhost',
              singleFile: true,
            },
            {
              name: 'Add to Production',
              href: '/docs/workflow-collaboration/vercel-toolbar/in-production-and-localhost/add-to-production',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Layout Shift Tool',
          href: '/docs/workflow-collaboration/layout-shift-tool',
          description: 'Measure layout shifts in your application',
          singleFile: true,
        },
        {
          name: 'Interaction Timing Tool',
          href: '/docs/workflow-collaboration/interaction-timing-tool',
          description: 'Measure interaction timing in your application',
          singleFile: true,
        },
        {
          name: 'Accessibility Audit Tool',
          href: '/docs/workflow-collaboration/accessibility-audit-tool',
          description: 'Audit your application for accessibility issues',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Feature Flags',
      href: '/docs/workflow-collaboration/feature-flags',
      description: "View and override your application's feature flags",
      isProduct: true,
      posts: [
        {
          name: 'Flags SDK',
          href: '/docs/workflow-collaboration/feature-flags/feature-flags-pattern',
          posts: [
            {
              name: 'Flags SDK in Next.js',
              href: '/docs/workflow-collaboration/feature-flags/flags-pattern-nextjs',
              singleFile: true,
            },
            {
              name: 'Flags SDK in SvelteKit',
              href: '/docs/workflow-collaboration/feature-flags/flags-pattern-sveltekit',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Managing Flags from the Toolbar',
          href: '/docs/workflow-collaboration/feature-flags/using-vercel-toolbar',
          posts: [
            {
              name: 'Implement Flags in the Toolbar',
              href: '/docs/workflow-collaboration/feature-flags/implement-flags-in-toolbar',
              singleFile: true,
            },
            {
              name: 'Toolbar Flags Reference',
              href: '/docs/workflow-collaboration/feature-flags/supporting-feature-flags',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Integrate Flags with Vercel Platform',
          href: '/docs/workflow-collaboration/feature-flags/integrate-vercel-platform',
          posts: [
            {
              name: 'Runtime Logs',
              href: '/docs/workflow-collaboration/feature-flags/integrate-with-runtime-logs',
              singleFile: true,
            },
            {
              name: 'Web Analytics',
              href: '/docs/workflow-collaboration/feature-flags/integrate-with-web-analytics',
              singleFile: true,
            },
          ],
        },
        {
          name: '@vercel/flags',
          href: '/docs/workflow-collaboration/feature-flags/vercel-flags',
          posts: [
            {
              name: '@vercel/flags/next',
              href: '/docs/workflow-collaboration/feature-flags/nextjs-flags-reference',
              singleFile: true,
            },
            {
              name: '@vercel/flags/sveltekit',
              href: '/docs/workflow-collaboration/feature-flags/sveltekit-flags-reference',
              singleFile: true,
            },
          ],
        },
      ],
    },
    {
      name: 'Comments',
      href: '/docs/workflow-collaboration/comments',
      description:
        'Allow collaborators to give direct feedback on preview deployments',
      isProduct: true,
      posts: [
        {
          name: 'Enabling Comments',
          href: '/docs/workflow-collaboration/comments/how-comments-work',
          singleFile: true,
        },
        {
          name: 'Using Comments',
          href: '/docs/workflow-collaboration/comments/using-comments',
          singleFile: true,
        },
        {
          name: 'Managing Comments',
          href: '/docs/workflow-collaboration/comments/managing-comments',
          singleFile: true,
        },
        {
          name: 'Integrations',
          href: '/docs/workflow-collaboration/comments/integrations',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Edit Mode',
      href: '/docs/workflow-collaboration/edit-mode',
      description: 'Edit your content directly on your site',
      singleFile: true,
    },
    {
      name: 'Draft Mode',
      href: '/docs/workflow-collaboration/draft-mode',
      description: 'Preview changes before publishing',
      singleFile: true,
    },
    {
      name: 'Conformance',
      href: '/docs/workflow-collaboration/conformance',
      description:
        'Improve collaboration, productivity, and software quality at scale',
      isProduct: true,
      singleFile: true,
      posts: [
        {
          name: 'Getting Started',
          href: '/docs/workflow-collaboration/conformance/getting-started',
          singleFile: true,
        },
        {
          name: 'CLI',
          href: '/docs/workflow-collaboration/conformance/cli',
          singleFile: true,
        },
        {
          name: 'Dashboard',
          href: '/docs/workflow-collaboration/conformance/dashboard-overview',
          singleFile: true,
          posts: [
            {
              name: 'Catalog reference',
              href: '/docs/workflow-collaboration/conformance/dashboard-overview/catalog',
              singleFile: true,
            },
          ],
        },
        {
          name: 'Allowlist',
          href: '/docs/workflow-collaboration/conformance/allowlist',
          singleFile: true,
        },
        {
          name: 'Customizing Conformance',
          href: '/docs/workflow-collaboration/conformance/customize',
          singleFile: true,
        },
        {
          name: 'Rules',
          href: '/docs/workflow-collaboration/conformance/rules',
          hideChildrenSide: true,
          posts: [
            {
              name: 'BFCACHE_INTEGRITY_NO_UNLOAD_LISTENERS',
              href: '/docs/workflow-collaboration/conformance/rules/BFCACHE_INTEGRITY_NO_UNLOAD_LISTENERS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'BFCACHE_INTEGRITY_REQUIRE_NOOPENER_ATTRIBUTE',
              href: '/docs/workflow-collaboration/conformance/rules/BFCACHE_INTEGRITY_REQUIRE_NOOPENER_ATTRIBUTE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'ESLINT_CONFIGURATION',
              href: '/docs/workflow-collaboration/conformance/rules/ESLINT_CONFIGURATION',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'ESLINT_NEXT_RULES_REQUIRED',
              href: '/docs/workflow-collaboration/conformance/rules/ESLINT_NEXT_RULES_REQUIRED',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'ESLINT_REACT_RULES_REQUIRED',
              href: '/docs/workflow-collaboration/conformance/rules/ESLINT_REACT_RULES_REQUIRED',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'ESLINT_RULES_REQUIRED',
              href: '/docs/workflow-collaboration/conformance/rules/ESLINT_RULES_REQUIRED',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_MISSING_MODULARIZE_IMPORTS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_MISSING_MODULARIZE_IMPORTS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_MISSING_OPTIMIZE_PACKAGE_IMPORTS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_MISSING_OPTIMIZE_PACKAGE_IMPORTS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_MISSING_NEXT13_TYPESCRIPT_PLUGIN',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_MISSING_NEXT13_TYPESCRIPT_PLUGIN',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_MISSING_REACT_STRICT_MODE',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_MISSING_REACT_STRICT_MODE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_MISSING_SECURITY_HEADERS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_MISSING_SECURITY_HEADERS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_BEFORE_INTERACTIVE',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_BEFORE_INTERACTIVE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_CLIENT_DEPS_IN_MIDDLEWARE',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_CLIENT_DEPS_IN_MIDDLEWARE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_DYNAMIC_AUTO',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_DYNAMIC_AUTO',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_FETCH_IN_SERVER_PROPS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_FETCH_IN_SERVER_PROPS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_GET_INITIAL_PROPS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_GET_INITIAL_PROPS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_SELF_HOSTED_VIDEOS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_SELF_HOSTED_VIDEOS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_TURBO_CACHE',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_TURBO_CACHE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_SAFE_SVG_IMAGES',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_SAFE_SVG_IMAGES',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_SAFE_URL_IMPORTS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_SAFE_URL_IMPORTS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_UNNEEDED_GET_SERVER_SIDE_PROPS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_UNNEEDED_GET_SERVER_SIDE_PROPS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_ASYNC_PAGE',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_ASYNC_PAGE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_ASYNC_LAYOUT',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_ASYNC_LAYOUT',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_USE_NATIVE_FETCH',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_USE_NATIVE_FETCH',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_NO_PRODUCTION_SOURCE_MAPS',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_NO_PRODUCTION_SOURCE_MAPS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_USE_NEXT_FONT',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_USE_NEXT_FONT',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_USE_NEXT_IMAGE',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_USE_NEXT_IMAGE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_USE_NEXT_SCRIPT',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_USE_NEXT_SCRIPT',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_ASSIGN_WINDOW_LOCATION',
              href: '/docs/workflow-collaboration/conformance/rules/NO_ASSIGN_WINDOW_LOCATION',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_CORS_HEADERS',
              href: '/docs/workflow-collaboration/conformance/rules/NO_CORS_HEADERS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_DANGEROUS_HTML',
              href: '/docs/workflow-collaboration/conformance/rules/NO_DANGEROUS_HTML',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_DOCUMENT_WRITE',
              href: '/docs/workflow-collaboration/conformance/rules/NO_DOCUMENT_WRITE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_EVAL',
              href: '/docs/workflow-collaboration/conformance/rules/NO_EVAL',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_EXTERNAL_CSS_AT_IMPORTS',
              href: '/docs/workflow-collaboration/conformance/rules/NO_EXTERNAL_CSS_AT_IMPORTS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_FETCH_FROM_MIDDLEWARE',
              href: '/docs/workflow-collaboration/conformance/rules/NO_FETCH_FROM_MIDDLEWARE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_INSTANCEOF_ERROR',
              href: '/docs/workflow-collaboration/conformance/rules/NO_INSTANCEOF_ERROR',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_MIXED_ASYNC_MODULES',
              href: '/docs/workflow-collaboration/conformance/rules/NO_MIXED_ASYNC_MODULES',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_SERIAL_ASYNC_CALLS',
              href: '/docs/workflow-collaboration/conformance/rules/NO_SERIAL_ASYNC_CALLS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NO_VARIABLE_IMPORT_REFERENCES',
              href: '/docs/workflow-collaboration/conformance/rules/NO_VARIABLE_IMPORT_REFERENCES',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_JSON_DESCRIPTION_REQUIRED',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_JSON_DESCRIPTION_REQUIRED',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_JSON_DUPLICATE_DEPENDENCIES',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_JSON_DUPLICATE_DEPENDENCIES',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_JSON_NAME_REQUIRED',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_JSON_NAME_REQUIRED',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_JSON_PRIVATE_REQUIRED',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_JSON_PRIVATE_REQUIRED',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_JSON_SIDE_EFFECTS_REQUIRED',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_JSON_SIDE_EFFECTS_REQUIRED',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_JSON_TYPE_REQUIRED',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_JSON_TYPE_REQUIRED',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_MANAGEMENT_NO_CIRCULAR_IMPORTS',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_MANAGEMENT_NO_CIRCULAR_IMPORTS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_MANAGEMENT_NO_UNRESOLVED_IMPORTS',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_MANAGEMENT_NO_UNRESOLVED_IMPORTS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'PACKAGE_MANAGEMENT_REQUIRED_README',
              href: '/docs/workflow-collaboration/conformance/rules/PACKAGE_MANAGEMENT_REQUIRED_README',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'REACT_NO_STATIC_IMPORTS_IN_EVENT_HANDLERS',
              href: '/docs/workflow-collaboration/conformance/rules/REACT_NO_STATIC_IMPORTS_IN_EVENT_HANDLERS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'REACT_STABLE_CONTEXT_PROVIDER_VALUE',
              href: '/docs/workflow-collaboration/conformance/rules/REACT_STABLE_CONTEXT_PROVIDER_VALUE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'NEXTJS_REQUIRE_EXPLICIT_DYNAMIC',
              href: '/docs/workflow-collaboration/conformance/rules/NEXTJS_REQUIRE_EXPLICIT_DYNAMIC',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'REQUIRE_DOCS_ON_EXPORTED_FUNCTIONS',
              href: '/docs/workflow-collaboration/conformance/rules/REQUIRE_DOCS_ON_EXPORTED_FUNCTIONS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'REQUIRE_ONE_VERSION_POLICY',
              href: '/docs/workflow-collaboration/conformance/rules/REQUIRE_ONE_VERSION_POLICY',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'REQUIRE_NODE_VERSION_FILE',
              href: '/docs/workflow-collaboration/conformance/rules/REQUIRE_NODE_VERSION_FILE',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'SET_COOKIE_VALIDATION',
              href: '/docs/workflow-collaboration/conformance/rules/SET_COOKIE_VALIDATION',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'TESTS_NO_CONDITIONAL_ASSERTIONS',
              href: '/docs/workflow-collaboration/conformance/rules/TESTS_NO_CONDITIONAL_ASSERTIONS',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'TESTS_NO_ONLY',
              href: '/docs/workflow-collaboration/conformance/rules/TESTS_NO_ONLY',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'TYPESCRIPT_CONFIGURATION',
              href: '/docs/workflow-collaboration/conformance/rules/TYPESCRIPT_CONFIGURATION',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'TYPESCRIPT_ONLY',
              href: '/docs/workflow-collaboration/conformance/rules/TYPESCRIPT_ONLY',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'WORKSPACE_MISSING_CONFORMANCE_SCRIPT',
              href: '/docs/workflow-collaboration/conformance/rules/WORKSPACE_MISSING_CONFORMANCE_SCRIPT',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'WORKSPACE_MISSING_PACKAGE_JSON',
              href: '/docs/workflow-collaboration/conformance/rules/WORKSPACE_MISSING_PACKAGE_JSON',
              singleFile: true,
              isCode: true,
            },
          ],
        },
        {
          name: 'Custom Rules',
          href: '/docs/workflow-collaboration/conformance/custom-rules',
          posts: [
            {
              name: 'forbidden-code',
              href: '/docs/workflow-collaboration/conformance/custom-rules/forbidden-code',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'forbidden-dependencies',
              href: '/docs/workflow-collaboration/conformance/custom-rules/forbidden-dependencies',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'forbidden-imports',
              href: '/docs/workflow-collaboration/conformance/custom-rules/forbidden-imports',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'forbidden-packages',
              href: '/docs/workflow-collaboration/conformance/custom-rules/forbidden-packages',
              singleFile: true,
              isCode: true,
            },
            {
              name: 'forbidden-properties',
              href: '/docs/workflow-collaboration/conformance/custom-rules/forbidden-properties',
              singleFile: true,
              isCode: true,
            },
          ],
        },
        {
          name: 'Changelog',
          href: '/docs/workflow-collaboration/conformance/changelog',
          singleFile: true,
        },
      ],
    },
    {
      name: 'Code Owners',
      href: '/docs/workflow-collaboration/code-owners',
      singleFile: true,
      description:
        'Define users or teams that are responsible for your codebase',
      isProduct: true,
      posts: [
        {
          name: 'Getting Started',
          href: '/docs/workflow-collaboration/code-owners/getting-started',
          singleFile: true,
        },
        {
          name: 'CLI',
          href: '/docs/workflow-collaboration/code-owners/cli',
          singleFile: true,
        },
        {
          name: 'Code Approvers',
          href: '/docs/workflow-collaboration/code-owners/code-approvers',
          singleFile: true,
        },
        {
          name: 'Changelog',
          href: '/docs/workflow-collaboration/code-owners/changelog',
          singleFile: true,
        },
      ],
    },
  ],
};
